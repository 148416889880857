// Gatsby SSR API

const griddoWebfonts = (
	<link
		rel="preload"
		href="/webfonts.css"
		as="style"
		// This id is required in order to usen it later in the `builder.browser.js`
		id="griddo-webfonts"
		crossOrigin
		// This onload only works on Griddo Editor (AX)
		// The code to trigger the onload for the build phase (CX) is the builder.browser.js
		onLoad="this.onload=null;this.rel='stylesheet'"
	/>
);

const richSnippets = (
	<script
		type="application/ld+json"
		dangerouslySetInnerHTML={{
			__html: JSON.stringify({
				"@context": "https://schema.org",
				"@type": "ProfilePage",
				dateCreated: "2024-04-18T12:00:00-02:00",
				dateModified: "2024-04-18T12:00:00-02:00",
				mainEntity: {
					"@type": "Organization",
					name: "Secuoyas",
					alternateName: "secuoyas",
					description: "Designing Relationships",
					image: "https://images.griddo.secuoyas.com/home_2",
					sameAs: ["https://www.linkedin.com/company/secuoyas/"],
				},
			}),
		}}
	/>
);

// onRenderBody hook
function onRenderBody({ setHeadComponents, setBodyAttributes, pathname }) {
	// common headComponents
	const commonHeadComponents = [griddoWebfonts];

	// Rendered page components
	const cxComponents = [richSnippets];

	// Set different scripts for Griddo Builder and Griddo Editor.
	const headComponents =
		pathname === "ax-editor"
			? [...commonHeadComponents]
			: [...commonHeadComponents, cxComponents];

	setHeadComponents(headComponents);
	setBodyAttributes({ id: "griddo-site" });
}

export default {
	onRenderBody,
};
