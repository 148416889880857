import { CaseDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.ContentTypeModule<CaseDistributorProps> = {
	schemaType: "module",
	component: "CaseDistributor",
	category: "distributors",
	dataPacks: ["PROJECTS_PACK"],
	displayName: "CaseDistributor",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "ReferenceField",
					title: "Projects",
					key: "data",
					sources: [{ structuredData: "PROJECT" }],
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/CaseDistributor/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/CaseDistributor/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/CaseDistributor/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/CaseDistributor/layout4.png",
						},
					],
				},
				{ ...themeSelector },
				...verticalSpacing,
				{
					type: "RadioGroup",
					title: "Hover",
					key: "hover",
					options: [
						{ name: "yes", title: "Yes", value: "yes" },
						{ name: "no", title: "No", value: "no" },
					],
				},
			],
		},
	],

	default: {
		component: "CaseDistributor",
		hasDistributorData: true,
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		link: {
			component: "Button",
			variant: "primary",
			link: { text: "Ver más", linkType: "url", url: {}, modal: {} },
		},
		topSpacing: "medium",
		bottomSpacing: "medium",
		subtheme: "default",
		layout: "layout3",
		hover: "yes",
		data: {
			sources: [{ structuredData: "PROJECT" }],
			mode: "auto",
			order: "alpha",
			quantity: 2,
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CaseDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CaseDistributor/thumbnail@2x.png",
	},
};

export default schema;
