import { CLIENT_CATEGORY } from "./CLIENT_CATEGORY";
import { HOBBIES_CATEGORY } from "./HOBBIES_CATEGORY";
import { INDUSTRY_CATEGORY } from "./INDUSTRY_CATEGORY";
import { PROFILE_CATEGORY } from "./PROFILE_CATEGORY";
import { SERVICES_CATEGORY } from "./SERVICES_CATEGORY";
import { SKILLS_CATEGORY } from "./SKILLS_CATEGORY";

export default {
	INDUSTRY_CATEGORY,
	SERVICES_CATEGORY,
	PROFILE_CATEGORY,
	CLIENT_CATEGORY,
	HOBBIES_CATEGORY,
	SKILLS_CATEGORY,
};
