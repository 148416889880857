import { LegalTemplateProps } from "@autoTypes";
import { Schema } from "@griddo/core";
// Load default schema props from modules to use in the `default`
import innerHero from "@schemas/modules/InnerHero";

const schema: Schema.Template<LegalTemplateProps> = {
	schemaType: "template",
	displayName: "Legal Template",
	component: "LegalTemplate",
	type: { label: "Static", value: "static" },

	content: [
		{
			type: "ComponentContainer",
			title: "Inner Hero",
			whiteList: ["InnerHero"],
			key: "innerHero",
			hideable: true,
			mandatory: true,
		},

		{
			type: "ComponentContainer",
			title: "Wysiwyg",
			whiteList: ["Wysiwyg"],
			key: "wysiwyg",
			mandatory: true,
		},
	],

	default: {
		type: "template",
		templateType: "LegalTemplate",
		innerHero: {
			...innerHero.default,
		},
		wysiwyg: {
			component: "Wysiwyg",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/LegalTemplate/thumbnail@1x.png",
		"2x": "/thumbnails/templates/LegalTemplate/thumbnail@2x.png",
	},
};

export default schema;
