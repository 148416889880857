import { Schema } from "@griddo/core";
import { heading, themeSelector } from "@schemas/presets";
import { FooterProps } from "@ui/modules/Footer";

const schema: Schema.Footer<FooterProps> = {
	schemaType: "module",
	displayName: "Footer",
	component: "Footer",
	type: "footer",
	defaultNavigation: true,

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Title",
					type: "TextField",
					key: "title",
					mandatory: true,
					humanReadable: true,
				},

				{ ...heading, key: "footerTitle" },

				{
					title: "Description",
					type: "RichText",
					key: "description",
					placeholder: "Type a detail",
					helptext: "Describe in a few words the concept of company",
					hideable: true,
					isMockup: true,
					mandatory: true,
					humanReadable: true,
				},

				{
					title: "Phone",
					type: "TextField",
					key: "phone",
				},

				{
					title: "Email",
					type: "TextField",
					key: "email",
					validators: {
						format: "email",
					},
				},
			],
		},
		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					title: "Set as default",
					key: "setAsDefault",
					options: [
						{
							title: "Set as Footer default",
						},
					],
				},

				{
					title: "Select Menu SEO",
					key: "seoMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					helptext: "Select the site menu. It must first be created.",
				},

				{
					title: "Select menu legal",
					key: "legalMenu",
					type: "AsyncSelect",
					entity: "menu_containers",
					helptext: "Select the site menu. It must first be created.",
					mandatory: true,
				},

				{ ...themeSelector },
			],
		},
	],

	default: {
		component: "Footer",
		type: "footer",
		setAsDefault: false,
		subtheme: "inverse",
		title: "Lorem ipsum",
		footerTitle: {
			content: "Lorem ipsum",
			tag: "h3",
		},
		description: "Lorem ipsum paragraph",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Footer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Footer/thumbnail@2x.png",
	},
};

export default schema;
