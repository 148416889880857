import { Schema } from "@griddo/core";

export const CLIENT_CATEGORY: Schema.CategoryContentType = {
	dataPacks: ["PROJECTS_PACK"],
	title: "Client category",
	local: false,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
