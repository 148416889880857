import BasicTemplate from "./BasicTemplate";
import ContactTemplate from "./ContactTemplate";
import Error404 from "./Error404";
import LegalTemplate from "./LegalTemplate";
import ProjectDetail from "./ProjectDetail";
import ProjectList from "./ProjectList";

export default {
	BasicTemplate,
	ContactTemplate,
	LegalTemplate,
	Error404,
	ProjectDetail,
	ProjectList,
};
