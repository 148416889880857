import { UIFields } from "@griddo/core";

export const anchor: UIFields.TextField = {
	title: "Anchor ID",
	type: "TextField",
	key: "anchor",
	prefix: "#",
	helptext:
		"Do not use uppercase letter or spaces and cannot start with numbers. Example: this-is-an-anchor.",
};
