import BasicContent from "./BasicContent";
import BasicText from "./BasicText";
import CardCollection from "./CardCollection";
import CardCollectionSlider from "./CardCollectionSlider";
import CaseDistributor from "./CaseDistributor";
import Cases from "./Cases";
import Contact from "./Contact";
import CustomImage from "./CustomImage";
import CustomVideo from "./CustomVideo";
import CypherCollection from "./CypherCollection";
import DataCollection from "./DataCollection";
import FeaturedAudiovisual from "./FeaturedAudiovisual";
import Footer from "./Footer";
import FullVisual from "./FullVisual";
import GraphicModules from "./GraphicModules";
import Header from "./Header";
import HeroProject from "./HeroProject";
import HeroVideo from "./HeroVideo";
import HorizontalScrollCardCollection from "./HorizontalScrollCardCollection";
import InnerHero from "./InnerHero";
import MasonryAudiovisual from "./MasonryAudiovisual";
import PageDistributor from "./PageDistributor";
import ProjectDistributor from "./ProjectDistributor";
import Quote from "./Quote";
import Showcase from "./Showcase";
import Slider from "./Slider";
import Spacer from "./Spacer";
import TeamDistributor from "./TeamDistributor";
import Timeline from "./Timeline";
import Wysiwyg from "./Wysiwyg";

export default {
	BasicContent,
	BasicText,
	CardCollection,
	CardCollectionSlider,
	CaseDistributor,
	Cases,
	Contact,
	CustomImage,
	CustomVideo,
	CypherCollection,
	DataCollection,
	FeaturedAudiovisual,
	Footer,
	FullVisual,
	GraphicModules,
	Header,
	HeroProject,
	HeroVideo,
	HorizontalScrollCardCollection,
	InnerHero,
	MasonryAudiovisual,
	PageDistributor,
	ProjectDistributor,
	Quote,
	Showcase,
	Slider,
	Spacer,
	TeamDistributor,
	Timeline,
	Wysiwyg,
};
