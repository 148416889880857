import { Schema } from "@griddo/core";

export const SERVICES_CATEGORY: Schema.CategoryContentType = {
	dataPacks: ["PROJECTS_PACK"],
	title: "Services category",
	local: false,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
