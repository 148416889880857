import { ContactProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	heading,
	anchor,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<ContactProps> = {
	schemaType: "module",
	component: "Contact",
	category: "content",
	displayName: "Contact",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ReferenceField",
					title: "Contact people",
					key: "data",
					sources: [{ structuredData: "PEOPLE" }],
					maxItems: 3,
					selectionType: ["manual"],
				},
				{
					type: "NoteField",
					key: "warningFlows",
					title: "",
					value: {
						title: "",
						text: "Since we are going to fill in a dynamic content, which changes depending on what the user selects, the content is divided by the different options (video call, call or email) and steps that each one contains",
					},
				},

				/***
				 * VIDEO CALL
				 ***/
				{
					type: "FieldGroup",
					title: "Video Call",
					key: "videoCall",
					helptext: `In this group we will fill in everything related to the steps of "making a video call"`,
					fields: [
						// STEP 1
						{
							type: "NoteField",
							key: "warningVideoCallStep1",
							title: "",
							value: {
								title: "Step 1",
								text: "Here we will include the information related to the first screen",
							},
						},
						{ ...heading, key: "videoCallStep1Title", isMockup: false },
						{
							type: "RichText",
							title: "Content",
							key: "videoCallStep1Content",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "TextField",
							title: "Help text Option 1",
							key: "videoCallStep1HelpTextOpt1",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "TextField",
							title: "Help text Option 2",
							key: "videoCallStep1HelpTextOpt2",
							hideable: true,
							humanReadable: true,
						},

						// STEP 2
						{
							type: "NoteField",
							key: "warningVideoCallStep2",
							title: "",
							value: {
								title: "Step 2",
								text: "Here we will include the information related to the second screen",
							},
						},
						{ ...heading, key: "videoCallStep2Title", isMockup: false },
						{
							type: "RichText",
							title: "Content",
							key: "videoCallStep2Content",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "TextField",
							title: "Summary of selected data 1",
							key: "videoCallStep2Summary1",
							hideable: true,
							humanReadable: true,
							mandatory: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
						},
						{
							type: "TextField",
							title: "Summary of selected data 2",
							key: "videoCallStep2Summary2",
							hideable: true,
							humanReadable: true,
							mandatory: true,
							helptext: "Use {{date}} to indicate the date in the text",
						},
						{
							type: "ComponentContainer",
							title: "Privacy Policy link",
							key: "videoCallStep2PrivacyPolicy",
							whiteList: ["Button"],
							hideable: true,
						},

						// CONFIRMATION
						{
							type: "NoteField",
							key: "warningVideoCallConfirmation",
							title: "",
							value: {
								title: "Confirmation",
								text: "Here we will include the information related to the last screen",
							},
						},
						{
							...heading,
							key: "videoCallStep3Title",
							isMockup: false,
							helptext:
								"Use {{user}} to indicate the person who is sending the form in the text",
						},
						{
							type: "RichText",
							title: "Content",
							key: "videoCallStep3Content",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "TextField",
							title: "Summary of selected data 1 (option 1)",
							key: "videoCallStep3Summary1a",
							hideable: true,
							humanReadable: true,
							mandatory: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
						},
						{
							type: "TextField",
							title: "Summary of selected data 1 (option 2)",
							key: "videoCallStep3Summary1b",
							hideable: true,
							humanReadable: true,
							mandatory: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
						},
						{
							type: "ComponentContainer",
							title: "Media",
							whiteList: ["FullImage", "Video"],
							key: "videoCallStep3media",
							mandatory: true,
							helptext: "Recommended minimum image size: 792x514",
							hideable: true,
						},
					],
				},

				/***
				 * PHONE CALL
				 ***/
				{
					type: "FieldGroup",
					title: "Call",
					key: "makeCall",
					helptext: `In this group we will fill in everything related to the steps of "making a phone call"`,
					fields: [
						// STEP 1
						{
							type: "NoteField",
							key: "warningCallStep1",
							title: "",
							value: {
								title: "Step 1",
								text: "Here we will include the information related to the first screen",
							},
						},
						{ ...heading, key: "callStep1Title", isMockup: false },
						{
							type: "RichText",
							title: "Content",
							key: "callStep1Content",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "ComponentContainer",
							title: "Privacy Policy link",
							key: "callStep1PrivacyPolicy",
							whiteList: ["Button"],
							hideable: true,
						},

						// CONFIRMATION
						{
							type: "NoteField",
							key: "warningCallConfirmation",
							title: "",
							value: {
								title: "Confirmation",
								text: "Here we will include the information related to the last screen",
							},
						},
						{
							...heading,
							key: "callStep2Title",
							isMockup: false,
							helptext:
								"Use {{user}} to indicate the person who is sending the form in the text",
						},
						{
							type: "RichText",
							title: "Content",
							key: "callStep2Content",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "TextField",
							title: "Summary of selected data 1",
							key: "callStep2Summary1",
							hideable: true,
							humanReadable: true,
							mandatory: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
						},
						{
							type: "TextField",
							title: "Summary of selected data 2",
							key: "callStep2Summary2",
							hideable: true,
							humanReadable: true,
							mandatory: true,
							helptext:
								"Use {{phone}} and {{time}} to indicate the phone number and the time slot in the text",
						},
						{
							type: "ComponentContainer",
							title: "Media",
							whiteList: ["FullImage", "Video"],
							key: "callStep2media",
							mandatory: true,
							helptext: "Recommended minimum image size: 792x514",
							hideable: true,
						},
					],
				},

				/***
				 * WRITE EMAIL
				 ***/
				{
					type: "FieldGroup",
					title: "Email",
					key: "writeEmail",
					helptext: `In this group we will fill in everything related to the steps of "send a email"`,
					fields: [
						// STEP 1
						{
							type: "NoteField",
							key: "warningEmailStep1",
							title: "",
							value: {
								title: "Step 1",
								text: "Here we will include the information related to the first screen",
							},
						},
						{ ...heading, key: "emailStep1Title", isMockup: false },
						{
							type: "RichText",
							title: "Content",
							key: "emailStep1Content",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "ComponentContainer",
							title: "Privacy Policy link",
							key: "emailStep1PrivacyPolicy",
							whiteList: ["Button"],
							hideable: true,
						},

						// CONFIRMATION
						{
							type: "NoteField",
							key: "warningEmailConfirmation",
							title: "",
							value: {
								title: "Confirmation",
								text: "Here we will include the information related to the last screen",
							},
						},
						{
							...heading,
							key: "emailStep2Title",
							isMockup: false,
							helptext:
								"Use {{user}} to indicate the person who is sending the form in the text",
						},
						{
							type: "RichText",
							title: "Content",
							key: "emailStep2Content",
							hideable: true,
							humanReadable: true,
						},
						{
							type: "TextField",
							title: "Summary of selected data 1",
							key: "emailStep2Summary",
							hideable: true,
							humanReadable: true,
							mandatory: true,
							helptext:
								"Use double curly brackets to color words. For example: Lorem {{ipsum}}",
						},
						{
							type: "ComponentContainer",
							title: "Media",
							whiteList: ["FullImage", "Video"],
							key: "emailStep2media",
							mandatory: true,
							helptext: "Recommended minimum image size: 792x514",
							hideable: true,
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "Contact",
		hasDistributorData: true,
		data: {
			sources: [{ structuredData: "PEOPLE" }],
			mode: "manual",
		},
		topSpacing: "small",
		bottomSpacing: "small",

		// VIDEO CALL
		videoCallStep1Title: {
			content: "Comparte tu misión con nosotros",
			tag: "h2",
		},
		videoCallStep1Content:
			"Ya sea tomando un café, a través de un email o en una llamada, nos encantaría conocerte bien y saber que te llevó hasta nosotros.",
		videoCallStep1HelpTextOpt1:
			"Esta es nuestra disponibilidad. Selecciona el día y la hora a la que prefieres que tengamos nuestro café.",
		videoCallStep1HelpTextOpt2:
			"Dinos cuál es tu disponibilidad y haremos todo lo posible para adaptarnos.",
		videoCallStep2Title: {
			content: "Hemos recibido tu propuesta, ¿nos cuentas un poco más?",
			tag: "h2",
		},
		videoCallStep2Content:
			"Entender las necesidades del proyecto es fundamental para nosotros. No nos aventuramos a decir sí a un nuevo reto si no estamos completamente seguros de que podemos hacerlo.",
		videoCallStep2Summary1: "Tomaremos el café a través de {{Google Meet}}",
		videoCallStep2Summary2:
			"Haremos todo lo posible para reunirnos el {{date}}. Si por algún motivo nos fuera imposible, te contactaríamos para buscar otro momento.",
		videoCallStep2PrivacyPolicy: {
			component: "Button",
			link: {
				text: "Política de Privacidad",
				linkType: "url",
				url: { href: "https://griddo.io/", newTab: true },
				modal: {},
			},
		},
		videoCallStep3Title: {
			content: "¡Todo listo, {{user}}!",
			tag: "h2",
		},
		videoCallStep3Content: "Hablamos muy pronto.",
		videoCallStep3Summary1a:
			"{{Txema Sotuela}} será quien te ayude a resolver cualquier duda",
		videoCallStep3Summary1b:
			"Uno de nuestros compañeros se pondrá en contacto contigo para confirmar nuestra cita",
		videoCallStep3media: {
			fullImage: {
				component: "FullImage",
				imageField: { url: "https://images.dev.griddo.io/secuoyas-gradient" },
			},
			video: {
				component: "Video",
			},
		},

		// PHONE CALL
		callStep1Title: {
			content: "Comparte tu misión con nosotros",
			tag: "h2",
		},
		callStep1Content:
			"Ya sea tomando un café, a través de un email o en una llamada, nos encantaría conocerte bien y saber que te llevó hasta nosotros.",
		callStep1PrivacyPolicy: {
			component: "Button",
			link: {
				text: "Política de Privacidad",
				linkType: "url",
				url: { href: "https://griddo.io/", newTab: true },
				modal: {},
			},
		},
		callStep2Title: {
			content: "¡Todo listo, {{user}}!",
			tag: "h2",
		},
		callStep2Content: "Hablamos muy pronto.",
		callStep2Summary1:
			"{{Txema Sotuela}} será quien te ayude a resolver cualquier duda",
		callStep2Summary2:
			"Te llamaremos al {{phone}} dentro del horario indicado {{time}}",
		callStep2media: {
			fullImage: {
				component: "FullImage",
				imageField: { url: "https://images.dev.griddo.io/secuoyas-gradient" },
			},
			video: {
				component: "Video",
			},
		},

		// EMAIL
		emailStep1Title: {
			content: "Comparte tu misión con nosotros",
			tag: "h2",
		},
		emailStep1Content:
			"Ya sea tomando un café, a través de un email o en una llamada, nos encantaría conocerte bien y saber que te llevó hasta nosotros.",
		emailStep1PrivacyPolicy: {
			component: "Button",
			link: {
				text: "Política de Privacidad",
				linkType: "url",
				url: { href: "https://griddo.io/", newTab: true },
				modal: {},
			},
		},
		emailStep2Title: {
			content: "¡Todo listo, {{user}}!",
			tag: "h2",
		},
		emailStep2Content: "Hablamos muy pronto.",
		emailStep2Summary:
			"{{Txema Sotuela}} será quien te ayude a resolver cualquier duda",
		emailStep2media: {
			fullImage: {
				component: "FullImage",
				imageField: { url: "https://images.dev.griddo.io/secuoyas-gradient" },
			},
			video: {
				component: "Video",
			},
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Contact/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Contact/thumbnail@2x.png",
	},
};

export default schema;
