import { CasesProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<CasesProps> = {
	schemaType: "module",
	component: "Cases",
	category: "content",
	displayName: "Cases",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "ComponentArray",
					title: "Cases",
					key: "elements",
					mandatory: true,
					contentType: "components",
					whiteList: ["Case"],
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/CaseDistributor/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/CaseDistributor/layout2.png",
						},
						{
							value: "layout3",
							img: "/thumbnails/modules/CaseDistributor/layout3.png",
						},
						{
							value: "layout4",
							img: "/thumbnails/modules/CaseDistributor/layout4.png",
						},
					],
				},
				{ ...themeSelector },
				...verticalSpacing,
				{
					type: "RadioGroup",
					title: "Hover",
					key: "hover",
					options: [
						{ name: "yes", title: "Yes", value: "yes" },
						{ name: "no", title: "No", value: "no" },
					],
				},
			],
		},
	],

	default: {
		component: "Cases",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		link: {
			component: "Button",
			variant: "primary",
			link: { text: "Ver más", linkType: "url", url: {}, modal: {} },
		},
		elements: [],
		topSpacing: "medium",
		bottomSpacing: "medium",
		subtheme: "default",
		layout: "layout3",
		hover: "yes",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/CaseDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/CaseDistributor/thumbnail@2x.png",
	},
};

export default schema;
