import { Schema } from "@griddo/core";

const schema: Schema.DamDefaults = {
	quality: 75,
	crop: "cover",
	loading: "lazy",
	decoding: "async",
	formats: ["webp"],
};

export default schema;
