/* eslint-disable import/order */

// elements schemas
import components from "./src/schemas/components";
import frontlessComponents from "./src/schemas/components/frontless";
import modules from "./src/schemas/modules";
import templates from "./src/schemas/templates";
import { Schema } from "@griddo/core";

// config schemas
import {
	languages,
	menuItems,
	menus,
	moduleCategories,
	themes,
} from "./src/schemas/config";

// contentTypes schemas
import {
	categories,
	pageContentType,
	simpleContentType,
} from "./src/schemas/content-types";

// datapacks schemas
import { dataPacks, dataPacksCategories } from "./src/schemas/data-packs";

// bundle for API
const schemas = {
	all: { ...frontlessComponents, ...components, ...modules },
	modules,
	templates,
	dataPacks,
	dataPacksCategories,
	structuredData: {
		...simpleContentType,
		...pageContentType,
		...categories,
	},
	languages,
	menus,
	menuItems,
};

const autotypes = {
	typeFileName: "autotypes.d.ts",
	interfaceSuffix: "Props",
	contentTypeSuffix: "ContentType",
	publicApiSuffix: "List",
} satisfies Schema.AutoTypesConfig;

export { schemas, themes, moduleCategories, autotypes };
