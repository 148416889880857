import { Schema } from "@griddo/core";

const schema: Schema.Component = {
	schemaType: "component",
	component: "Milestone",
	displayName: "Milestone",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Year",
					type: "NumberField",
					key: "year",
					validators: { minValue: 2000 },
					mandatory: true,
					hideable: false,
				},

				{
					title: "Content",
					type: "TextField",
					key: "content",
					humanReadable: true,
					isMockup: true,
				},

				{
					title: "Image",
					type: "ComponentContainer",
					key: "image",
					whiteList: ["FullImage"],
					hideable: false,
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "Milestone",
		year: new Date().getFullYear(),
		content: "Lorem ipsum",
		image: {
			component: "FullImage",
			imageField: {
				url: "https://images.griddo.secuoyas.com/no04-napkin-b-2880x1800",
			},
			veil: 50,
		},
	},

	thumbnails: {
		"1x": "/thumbnails/objects/Milestone/thumbnail@1x.png",
		"2x": "/thumbnails/objects/Milestone/thumbnail@2x.png",
	},
};

export default schema;
