import { Schema } from "@griddo/core";

const schema: Schema.Component = {
	schemaType: "component",
	component: "LinkableImage",
	displayName: "Linkable Image",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Link",
					type: "UrlField",
					key: "link",
					helptext: "The link of the image",
				},

				{
					title: "Image",
					type: "ImageField",
					key: "imageField",
					helptext: "The image file.",
				},

				{
					title: "Alternative Text",
					type: "TextField",
					key: "alt",
					helptext: "Set the alternative text for the image.",
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "LinkableImage",
		link: null,
		imageField: null,
		alt: "",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/LinkableImage/thumbnail@1x.png",
		"2x": "/thumbnails/templates/LinkableImage/thumbnail@2x.png",
	},
};

export default schema;
