import { Schema } from "@griddo/core";

const schema: Schema.MenuItem = {
	link: {
		fields: [],
	},

	group: {
		fields: [],
	},
};

export default schema;
