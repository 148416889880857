import { Fields } from "@griddo/core";

type DefaultLinkValues = {
	url: Fields.Url;
};

export const defaultLink: DefaultLinkValues = {
	url: {
		href: undefined,
		linkToURL: undefined,
		newTab: false,
		noFollow: false,
	},
};
