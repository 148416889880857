import { VideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";

const schema: Schema.Component<VideoProps> = {
	schemaType: "component",
	component: "Video",
	displayName: "Video",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "TextField",
					key: "url",
					title: "URL video",
					validators: { format: "URL" },
					mandatory: true,
				},

				{
					title: "Alternative text",
					type: "TextField",
					key: "altText",
					placeholder: "Type an alternative text",
					humanReadable: true,
				},

				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},

				{
					title: "Thumbnail",
					type: "ImageField",
					key: "image",
					helptext: "Recommended minimum image size: 2508x1030px",
				},

				{
					title: "Visual behavior",
					key: "show",
					type: "RadioGroup",
					options: [
						{ name: "video", value: "video", title: "Play video" },
						{ name: "image", value: "image", title: "Show thumbnail" },
					],
				},

				{
					type: "UniqueCheck",
					title: "On click",
					key: "openModal",
					options: [
						{
							title: "Open video in a modal",
						},
					],
				},

				{
					type: "ToggleField",
					title: "Play in loop",
					key: "playLoop",
				},

				{
					title: "Delay",
					type: "SliderField",
					key: "delay",
					step: 0.25,
					min: 0,
					max: 2,
				},
			],
		},
	],

	default: {
		component: "Video",
		url: "",
		image: undefined,
		altText: "",
		veil: 0,
		show: "video",
		openModal: false,
		playLoop: true,
		delay: 0,
	},

	thumbnails: {
		"1x": "/thumbnails/objects/Video/thumbnail@1x.png",
		"2x": "/thumbnails/objects/Video/thumbnail@2x.png",
	},
};

export default schema;
