import { UIFields } from "@griddo/core";

export const verticalSpacing: Array<UIFields.RadioGroup> = [
	{
		type: "RadioGroup",
		title: "Top Spacing",
		key: "topSpacing",
		mandatory: true,
		options: [
			{ name: "small", title: "Small", value: "small" },
			{ name: "medium", title: "Medium", value: "medium" },
			{ name: "large", title: "Large", value: "large" },
		],
	},
	{
		type: "RadioGroup",
		title: "Bottom Spacing",
		key: "bottomSpacing",
		mandatory: true,
		options: [
			{ name: "small", title: "Small", value: "small" },
			{ name: "medium", title: "Medium", value: "medium" },
			{ name: "large", title: "Large", value: "large" },
		],
	},
];
