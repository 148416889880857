import { Schema } from "@griddo/core";

const schema: Schema.Menu = [
	{
		key: "mainNav",
		display: "Main navigation",
	},

	{
		key: "seoNav",
		display: "SEO navigation",
	},

	{
		key: "legalNav",
		display: "Legal navigation",
	},
];

export default schema;
