import { Schema } from "@griddo/core";

export const PEOPLE: Schema.SimpleContentType = {
	dataPacks: ["PEOPLE_PACK"],
	title: "People",
	local: false,
	translate: true,
	clone: null,
	fromPage: false,

	schema: {
		fields: [
			{
				key: "title",
				title: "Name",
				placeholder: "Type a name",
				type: "TextField",
				mandatory: true,
			},

			{
				key: "image1",
				title: "Employee image 1",
				type: "ImageField",
				mandatory: true,
				helptext: "Shown on the distributor card, portrait image",
			},

			{
				key: "image2",
				title: "Employee image 2",
				type: "ImageField",
				helptext:
					"Image for the detail tab of the culture website, the person is carrying out his hobby",
			},

			{
				key: "description",
				title: "Description",
				placeholder: "Type a description",
				type: "RichText",
			},

			{
				title: "Profile LinkedIn",
				type: "UrlField",
				key: "linkedin",
			},

			// CATEGORIES
			{
				type: "MultiCheckSelectGroup",
				key: "categories",
				title: "Categories",
				elements: [
					{
						key: "industryCategory",
						source: "INDUSTRY_CATEGORY",
						placeholder: "Industry",
					},
					{
						key: "servicesCategory",
						source: "SERVICES_CATEGORY",
						placeholder: "Services",
					},
					{
						key: "profileCategory",
						source: "PROFILE_CATEGORY",
						placeholder: "Profiles",
					},
					{
						key: "hobbiesCategory",
						source: "HOBBIES_CATEGORY",
						placeholder: "Hobbies",
					},
				],
			},

			// SKILLS
			{
				key: "dividerSkills",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Skills",
					text: "",
				},
			},
			{
				title: "Plays an instrument - Listen to music",
				type: "SliderField",
				key: "musicSkills",
				step: 10,
				min: 0,
				max: 100,
				mandatory: true,
			},
			{
				title: "Paints - Colors",
				type: "SliderField",
				key: "colorsSkills",
				step: 10,
				min: 0,
				max: 100,
				mandatory: true,
			},

			// FUN
			{
				key: "dividerFun",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Fun",
					text: "",
				},
			},
			{
				title: "Table games - Video games",
				type: "SliderField",
				key: "gamesFun",
				step: 10,
				min: 0,
				max: 100,
				mandatory: true,
			},
			{
				title: "Smart humour - Absurd jokes",
				type: "SliderField",
				key: "humourFun",
				step: 10,
				min: 0,
				max: 100,
				mandatory: true,
			},

			// LIKES
			{
				key: "dividerLikes",
				title: "",
				type: "FieldsDivider",
				data: {
					title: "Likes",
					text: "",
				},
			},
			{
				title: "Catty Love - Dog Love",
				type: "SliderField",
				key: "petLikes",
				step: 10,
				min: 0,
				max: 100,
				mandatory: true,
			},
			{
				title: "Coffe - Coca Cola",
				type: "SliderField",
				key: "drinkLikes",
				step: 10,
				min: 0,
				max: 100,
				mandatory: true,
			},
		],
	},

	defaultValues: {
		musicSkills: 50,
		colorsSkills: 50,
		gamesFun: 50,
		humourFun: 50,
		petLikes: 50,
		drinkLikes: 50,
	},
};
