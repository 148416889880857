import { Schema } from "@griddo/core";
import { defaultLink } from "@schemas/presets";

const schema: Schema.Component = {
	schemaType: "component",
	displayName: "Icon Button",
	component: "IconButton",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					_autoType: {
						doc: "/** Url field type */",
					},
					title: "Link",
					type: "UrlField",
					key: "url",
					advanced: true,
					mandatory: true,
				},

				{
					_autoType: {
						doc: "/** Icon choice */",
					},
					title: "Icon",
					key: "icon",
					type: "VisualUniqueSelection",
					columns: 8,
					options: [
						{
							value: "arrowRight",
							img: "/thumbnails/components/IconButton/icons/arrow-right@2x.png",
						},
						{
							value: "arrowLeft",
							img: "/thumbnails/components/IconButton/icons/arrow-left@2x.png",
						},
						{
							value: "arrowUp",
							img: "/thumbnails/components/IconButton/icons/arrow-up@2x.png",
						},
						{
							value: "arrowDown",
							img: "/thumbnails/components/IconButton/icons/arrow-down@2x.png",
						},
						{
							value: "carretRight",
							img: "/thumbnails/components/IconButton/icons/carret-right@2x.png",
						},
						{
							value: "carretLeft",
							img: "/thumbnails/components/IconButton/icons/carret-left@2x.png",
						},
						{
							value: "carretUp",
							img: "/thumbnails/components/IconButton/icons/carret-up@2x.png",
						},
						{
							value: "carretDown",
							img: "/thumbnails/components/IconButton/icons/carret-down@2x.png",
						},
						{
							value: "calendar",
							img: "/thumbnails/components/IconButton/icons/calendar@2x.png",
						},
						{
							value: "plus",
							img: "/thumbnails/components/IconButton/icons/plus@2x.png",
						},
						{
							value: "minus",
							img: "/thumbnails/components/IconButton/icons/minus@2x.png",
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					_autoType: {
						doc: "/** Button variant style */",
					},
					title: "Variant",
					key: "variant",
					type: "VisualUniqueSelection",
					columns: 8,
					options: [
						{
							value: "primary",
							img: "/thumbnails/components/IconButton/styles/primary@2x.png",
						},
						{
							value: "secondary",
							img: "/thumbnails/components/IconButton/styles/secondary@2x.png",
						},
						{
							value: "text",
							img: "/thumbnails/components/IconButton/styles/text-button@2x.png",
						},
						{
							value: "cta",
							img: "/thumbnails/components/IconButton/styles/cta@2x.png",
						},
					],
				},
			],
		},
	],

	default: {
		...defaultLink,
		component: "IconButton",
		variant: "primary",
		icon: "arrowRight",
	},
};

export default schema;
