import { TimelineProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<TimelineProps> = {
	schemaType: "module",
	component: "Timeline",
	category: "content",
	displayName: "Timeline",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "ComponentArray",
					title: "Milestones",
					key: "milestones",
					mandatory: true,
					contentType: "components",
					whiteList: ["Milestone"],
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "Timeline",
		title: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		milestones: [
			{
				component: "Milestone",
				year: 2001,
				content: "Where all began",
				image: {
					component: "FullImage",
					imageField: {
						url: "https://images.griddo.secuoyas.com/no04-napkin-b-2880x1800",
					},
					veil: 50,
				},
			},
			{
				component: "Milestone",
				year: new Date().getFullYear(),
				content: "Today",
				image: {
					component: "FullImage",
					imageField: {
						url: "https://images.griddo.secuoyas.com/no04-napkin-b-2880x1800",
					},
					veil: 50,
				},
			},
		],
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "inverse",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Timeline/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Timeline/thumbnail@2x.png",
	},
};

export default schema;
