import { Schema } from "@griddo/core";
import { themeSelector } from "@schemas/presets";
import { SpacerProps } from "@ui/modules/Spacer";

const schema: Schema.Module<SpacerProps> = {
	schemaType: "module",
	component: "Spacer",
	category: "spacers",
	displayName: "Spacer",

	configTabs: [
		{
			title: "config",
			fields: [
				{
					title: "Size",
					type: "RadioGroup",
					key: "size",
					options: [
						{ name: "small", value: "small", title: "small" },
						{ name: "medium", value: "medium", title: "medium" },
						{ name: "large", value: "large", title: "large" },
					],
				},

				{ ...themeSelector },
			],
		},
	],

	default: {
		component: "Spacer",
		size: "small",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Spacer/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Spacer/thumbnail@2x.png",
	},
};

export default schema;
