import { GraphicModulesProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<GraphicModulesProps> = {
	schemaType: "module",
	component: "GraphicModules",
	category: "content",
	displayName: "Graphic Modules",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Intro",
					key: "intro",
					placeholder: "Type an intro",
					hideable: true,
					isMockup: true,
					humanReadable: true,
					helptext:
						"Write plain text and select the heading type. Use double curly brackets to color words. For example: Lorem {{ipsum}}",
				},

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
				},

				{
					type: "ComponentContainer",
					key: "graphic",
					title: "Graphic",
					whiteList: [
						"SquiggleGraphic",
						"GoldenCircleGraphic",
						"DigitalProductGraphic",
						"DesignOpsGraphic",
						"DevOpsGraphic",
						"DataGraphic",
						"StrategyGraphic",
					],
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "GraphicModules",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		intro: "Lorem Ipsum",
		content: "Lorem Ipsum",
		graphic: {
			squiggleGraphic: { component: "SquiggleGraphic" },
			goldenCircleGraphic: { component: "GoldenCircleGraphic" },
			digitalProductGraphic: { component: "DigitalProductGraphic" },
			designOpsGraphic: { component: "DesignOpsGraphic" },
			devOpsGraphic: { component: "DevOpsGraphic" },
			dataGraphic: { component: "DataGraphic" },
			strategyGraphic: { component: "StrategyGraphic" },
		},
		topSpacing: "medium",
		bottomSpacing: "medium",
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/GraphicModules/thumbnail@1x.png",
		"2x": "/thumbnails/modules/GraphicModules/thumbnail@2x.png",
	},
};

export default schema;
