import { ProjectListProps } from "@autoTypes";
import { Schema } from "@griddo/core";
// Load default schema props from modules to use in the `default`
import featuredAudiovisual from "@schemas/modules/FeaturedAudiovisual";
import innerHero from "@schemas/modules/InnerHero";
import projectDistributor from "@schemas/modules/ProjectDistributor";
import { allModules } from "@schemas/presets";

const schema: Schema.Template<ProjectListProps> = {
	schemaType: "template",
	displayName: "Project List",
	component: "ProjectList",
	dataPacks: ["PROJECTS_PACK"],
	type: { label: "Static", value: "static" },

	content: [
		{
			type: "ComponentContainer",
			title: "Inner Hero",
			whiteList: ["InnerHero"],
			key: "innerHero",
			hideable: true,
			mandatory: true,
		},

		{
			type: "ComponentContainer",
			title: "Project Distributor",
			whiteList: ["ProjectDistributor"],
			key: "projects",
			mandatory: true,
		},

		{
			type: "ComponentContainer",
			title: "Featured Audiovisual",
			whiteList: ["FeaturedAudiovisual"],
			key: "featuredAudiovisual",
			hideable: true,
		},

		{
			type: "ComponentArray",
			title: "Related Content",
			key: "relatedContent",
			contentType: "modules",
			whiteList: allModules,
		},
	],

	default: {
		type: "template",
		templateType: "ProjectList",
		innerHero: {
			...innerHero.default,
			subtheme: "default",
		},
		projects: {
			...projectDistributor.default,
			layout: "layout2",
			title: null,
			intro: null,
			topSpacing: "small",
			bottomSpacing: "small",
		},
		featuredAudiovisual: {
			...featuredAudiovisual.default,
			subtheme: "inverse",
		},
		relatedContent: {
			component: "Section",
			name: "Related Content",
			modules: [],
		},
	},

	thumbnails: {
		"1x": "/thumbnails/templates/ProjectList/thumbnail@1x.png",
		"2x": "/thumbnails/templates/ProjectList/thumbnail@2x.png",
	},
};

export default schema;
