import { TeamDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import {
	anchor,
	heading,
	themeSelector,
	verticalSpacing,
} from "@schemas/presets";

const schema: Schema.Module<TeamDistributorProps> = {
	schemaType: "module",
	component: "TeamDistributor",
	category: "distributors",
	displayName: "Team Distributor",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Filter text",
					key: "filterText",
					hideable: false,
					humanReadable: true,
				},

				{
					title: "Default image",
					type: "ImageField",
					key: "defaultImage",
					helptext: "Image to show if someone does not have one",
				},

				{
					type: "ReferenceField",
					title: "People",
					key: "data",
					sources: [{ structuredData: "PEOPLE" }],
				},

				{
					type: "ComponentContainer",
					title: "Link",
					key: "link",
					whiteList: ["Button"],
					hideable: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }, ...verticalSpacing],
		},
	],

	default: {
		component: "TeamDistributor",
		hasDistributorData: true,
		data: {
			sources: [{ structuredData: "PEOPLE" }],
			mode: "auto",
			order: "alpha",
		},
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		filterText: "Descubre el equipo que podría ayudarte en tu proyecto",
		topSpacing: "large",
		bottomSpacing: "large",
		subtheme: "default",
		link: {
			component: "Button",
			link: { text: "Link", linkType: "url", url: {}, modal: {} },
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/TeamDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/TeamDistributor/thumbnail@2x.png",
	},
};

export default schema;
