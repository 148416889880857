import { UIFields } from "@griddo/core";

export const heading: UIFields.HeadingField = {
	type: "HeadingField",
	title: "Title",
	key: "title",
	default: { content: "Lorem ipsum", tag: "h2" },
	placeholder: "Type a title",
	advanced: true,
	helptext:
		"Write plain text and select the heading type. Use double curly brackets to color words. For example: Lorem {{ipsum}}",
	hideable: true,
	isMockup: true,
	mandatory: false,
	options: [
		{ label: "h1", value: "h1" },
		{ label: "h2", value: "h2" },
		{ label: "h3", value: "h3" },
	],
	humanReadable: true,
};
