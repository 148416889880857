import { Schema } from "@griddo/core";

export const SKILLS_CATEGORY: Schema.CategoryContentType = {
	dataPacks: ["PROJECTS_PACK"],
	title: "Skills category",
	local: false,
	translate: true,
	taxonomy: true,
	fromPage: false,
};
