import { HeaderProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { themeSelector } from "@schemas/presets";

const schema: Schema.Header<HeaderProps> = {
	component: "Header",
	defaultNavigation: true,
	displayName: "Header",
	schemaType: "module",
	type: "header",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Name",
					key: "title",
					type: "TextField",
					mandatory: true,
					placeholder: "Type a title",
					humanReadable: true,
				},

				{
					type: "Select",
					key: "logo",
					title: "Logo",
					mandatory: true,
					options: [
						{
							label: "Secuoyas",
							value: "secuoyas",
						},
					],
				},

				{
					type: "RadioGroup",
					title: "Show Ecosystem",
					key: "showEcosystem",
					mandatory: true,
					options: [
						{ name: "yes", title: "Yes", value: "yes" },
						{ name: "no", title: "No", value: "no" },
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					type: "UniqueCheck",
					key: "setAsDefault",
					title: "Set as default",
					options: [{ title: "Set as Header default" }],
				},

				{
					title: "Select Menu",
					key: "menu",
					type: "AsyncSelect",
					entity: "menu_containers",
					mandatory: true,
					helptext: "Select de site menu. It must first be created.",
				},

				{
					type: "UniqueCheck",
					title: "Keep sticky on scroll",
					key: "sticky",
					options: [{ title: "sticky" }],
				},

				{ ...themeSelector },

				{
					type: "RadioGroup",
					title: "Animation",
					key: "animation",
					options: [
						{ name: "yes", title: "Yes", value: "yes" },
						{ name: "no", title: "No", value: "no" },
					],
				},
			],
		},
	],

	default: {
		component: "Header",
		type: "header",
		title: "New Header",
		setAsDefault: true,
		showEcosystem: "yes",
		animation: "no",
		logo: "secuoyas",
		menu: {},
		sticky: false,
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Header/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Header/thumbnail@2x.png",
	},

	styles: {
		S1: "/thumbnails/modules/Header/style1.png",
		S2: "/thumbnails/modules/Header/style2.png",
	},
};

export default schema;
