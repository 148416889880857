import { Schema } from "@griddo/core";

const schema: Schema.Component = {
	schemaType: "component",
	component: "Case",
	displayName: "Case",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ImageField",
					key: "logo",
					title: "Client Logo",
				},

				{
					type: "RichText",
					key: "intro",
					title: "Intro",
					mandatory: true,
					humanReadable: true,
				},

				{
					type: "ImageField",
					key: "image",
					title: "Image",
				},
			],
		},
	],

	default: {
		component: "Case",
		intro: "Lorem ipsum",
	},

	thumbnails: {
		"1x": "/thumbnails/objects/Case/thumbnail@1x.png",
		"2x": "/thumbnails/objects/Case/thumbnail@2x.png",
	},
};

export default schema;
