export default {
	global: {
		skipToContent: "Skip to content",
		readMore: "Read more",
		seeMore: "See more",
		seeLess: "See less",
		availableSoon: "Available soon",
		followUs: "Follow us",
		filter: "Filter",
		continue: "Continue",
		hour: "Hour",
		date: "Date",
		send: "Send",
		emailUs: "Send us an email",
		of: "of",
	},

	categories: {
		client: "Client",
		industry: "Industry",
		services: "Services",
		team: "Team",
		date: "Date",
		skills: "Skills",
	},

	elements: {
		selectDropdown: {
			clearSelection: "Clear selection",
		},
	},

	components: {
		playButton: {
			watchVideo: "Watch",
		},
	},

	modules: {
		contact: {
			scheduleCoffee: "Schedule a coffee (video call)",
			writeEmail: "Write an email",
			makeCall: "Make a call",
			weProposeDates: "We propose dates",
			youProposeDates: "You propose us your availability",
			previousStep: "Go to previous step",
			privacyPolicy:
				"I have read and understand the information about the use of my personal data explained in the {{privacyPolicyLink}} and I agree to receive commercial communications via email.",
			form: {
				name: {
					label: "Name",
					placeholder: "Name and surname",
					error: "You have to write a valid name",
				},
				email: {
					label: "Professional email",
					placeholder: "Email",
					error: "You have to write a valid email",
				},
				message: {
					label: "What we should know ahead of our meeting",
					placeholder: "Your message",
				},
				company: {
					label: "Company",
					placeholder: "Company Name",
					error: "Please, tell us your company name",
				},
				phone: {
					label: "Phone",
					placeholder: "+34 000 000 000",
					error: "You have to write a valid phone number",
				},
				timeSlot: {
					label: "When would you prefer to be called?",
					placeholder: "Choose the time slot",
				},
			},
			error:
				"Oops, something seems to have gone wrong. Please fill out the form below so we can contact you.",
			errorButton: "Go to form",
		},

		footer: {
			cookiesConsent: "Cookies consent",
		},
	},
};
