import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component = {
	schemaType: "component",
	component: "ImageCard",
	displayName: "ImageCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					key: "media",
					title: "Media",
					whiteList: ["FullImage", "Video"],
				},

				{ ...heading },

				{
					type: "RichText",
					key: "content",
					title: "Content",
					placeholder: "Type a content",
					hideable: true,
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "ImageCard",
		media: {
			fullImage: {
				component: "FullImage",
			},
			video: {
				component: "Video",
			},
		},
		title: { content: "Lorem ipsum", tag: "h2" },
		content: "Lorem ipsum paragraph",
	},

	thumbnails: {
		"1x": "/thumbnails/components/ImageCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/ImageCard/thumbnail@2x.png",
	},
};

export default schema;
