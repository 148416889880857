import { Schema } from "@griddo/core";
import { anchor, themeSelector, verticalSpacing } from "@schemas/presets";
import { SliderProps } from "@ui/modules/Slider";

const schema: Schema.Module<SliderProps> = {
	schemaType: "module",
	component: "Slider",
	category: "sliders",
	displayName: "Slider",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentArray",
					title: "Slides",
					contentType: "components",
					key: "slides",
					whiteList: ["Slide"],
					mandatory: true,
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					title: "Layout",
					key: "layout",
					type: "VisualUniqueSelection",
					columns: 2,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/Slider/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/Slider/layout2.png",
						},
					],
					mandatory: true,
				},
				{ ...themeSelector },
				...verticalSpacing,
				{
					type: "ToggleField",
					title: "Show numbers on cards",
					key: "showNumbers",
				},
			],
		},
	],

	default: {
		component: "Slider",
		showNumbers: false,
		layout: "layout1",
		subtheme: "default",
		topSpacing: "medium",
		bottomSpacing: "medium",
		slides: [
			{
				component: "Slide",
				title: "Lorem",
				sectionTitle: "Section title",
				text: "Slider text",
			},
		],
	},

	thumbnails: {
		"1x": "/thumbnails/modules/Slider/thumbnail@1x.png",
		"2x": "/thumbnails/modules/Slider/thumbnail@2x.png",
	},
};

export default schema;
