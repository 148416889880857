//
// Griddo Templates
//
// Every Template must be imported and reexported.
// Yes, a barrel index :)

import * as React from "react";

// Templates
const BasicTemplate = React.lazy(() => import("./BasicTemplate"));
const ContactTemplate = React.lazy(() => import("./ContactTemplate"));
const Error404 = React.lazy(() => import("./Error404"));
const LegalTemplate = React.lazy(() => import("./LegalTemplate"));
const ProjectDetail = React.lazy(() => import("./ProjectDetail"));
const ProjectList = React.lazy(() => import("./ProjectList"));

// Griddo bundle export
export default {
	BasicTemplate,
	ContactTemplate,
	Error404,
	LegalTemplate,
	ProjectDetail,
	ProjectList,
};
