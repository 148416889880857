import { FullVisualProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchor, themeSelector } from "@schemas/presets";

const schema: Schema.Module<FullVisualProps> = {
	schemaType: "module",
	component: "FullVisual",
	category: "content",
	displayName: "FullVisual",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					type: "ComponentContainer",
					title: "Visual content",
					whiteList: ["Image", "Video"],
					key: "visualContent",
					mandatory: true,
				},
			],
		},

		{
			title: "config",
			fields: [{ ...anchor }, { ...themeSelector }],
		},
	],

	default: {
		component: "FullVisual",
		visualContent: {
			image: {
				component: "Image",
				imageField: { url: "https://images.dev.griddo.io/secuoyas-gradient" },
			},
			video: {
				component: "Video",
			},
		},
		subtheme: "default",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/FullVisual/thumbnail@1x.png",
		"2x": "/thumbnails/modules/FullVisual/thumbnail@2x.png",
	},
};

export default schema;
