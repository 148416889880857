import { DataCardProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { heading } from "@schemas/presets";

const schema: Schema.Component<DataCardProps> = {
	schemaType: "component",
	component: "DataCard",
	displayName: "DataCard",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					...heading,
				},

				{
					type: "TextField",
					title: "Number",
					key: "number",
					placeholder: "Type a number",
					hideable: true,
					mandatory: true,
				},

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a detail text",
					isMockup: true,
					humanReadable: true,
					mandatory: true,
				},
			],
		},
	],

	default: {
		component: "DataCard",
		title: { content: "Lorem ipsum", tag: "h2" },
		number: "000",
		content: "Lorem ipsum paragraph",
	},

	thumbnails: {
		"1x": "/thumbnails/components/DataCard/thumbnail@1x.png",
		"2x": "/thumbnails/components/DataCard/thumbnail@2x.png",
	},
};

export default schema;
