import { Schema } from "@griddo/core";

const schema: Schema.Component = {
	schemaType: "component",
	component: "Image",
	displayName: "Image",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					title: "Image",
					type: "ImageField",
					key: "imageField",
					helptext: "The image file.",
				},

				{
					title: "Alternative Text",
					type: "TextField",
					key: "alt",
					helptext: "Set the alternative text for the image.",
					humanReadable: true,
				},

				{
					title: "Veil percentage",
					type: "SliderField",
					key: "veil",
					step: 10,
					min: 0,
					max: 100,
				},
			],
		},
	],

	default: {
		component: "Image",
		imageField: undefined,
		alt: null,
		veil: 0,
	},

	thumbnails: {
		"1x": "/thumbnails/objects/Image/thumbnail@1x.png",
		"2x": "/thumbnails/objects/Image/thumbnail@2x.png",
	},
};

export default schema;
