import { Schema } from "@griddo/core";

export const PROJECT: Schema.PageContentType = {
	dataPacks: ["PROJECTS_PACK"],
	title: "Project",
	fromPage: true,
	translate: true,
	taxonomy: false, // not a category
	local: true,
	schema: {
		templates: ["ProjectDetail"],
		fields: [
			{
				key: "title",
				type: "TextField",
				title: "Title",
				from: "title",
			},

			// INTRO
			{
				key: "intro",
				title: "Intro",
				type: "RichText",
				from: "intro",
			},

			// IMAGE
			{
				key: "image",
				title: "Image",
				type: "ImageField",
				from: "image",
			},

			// LOGO
			{
				key: "logo",
				title: "Logo",
				type: "ImageField",
				from: "logo",
			},

			// LOGO
			{
				key: "projectState",
				title: "Project State",
				type: "RadioGroup",
				from: "projectState",
				options: [
					{ name: "wip", title: "Wip", value: "wip" },
					{ name: "finish", title: "Finished", value: "finish" },
				],
			},

			// OTHER PROJECT DATA
			{
				type: "TextField",
				key: "date",
				title: "Date",
				from: "date",
			},
			{
				type: "TagsField",
				key: "team",
				title: "Team",
				from: "team",
			},

			// CATEGORIES
			{
				title: "Client Category",
				type: "MultiCheckSelect",
				key: "clientCategory",
				source: "CLIENT_CATEGORY",
				from: "categories.clientCategory",
				//               ^
				// Es necesario acceder con dot notation:
				// `categories.clientCategory` porque las categorías están dentro de
				// un MultiCheckSelectGroup como `categories`
				placeholder: "Client",
			},

			{
				title: "Services Category",
				type: "MultiCheckSelect",
				key: "servicesCategory",
				source: "SERVICES_CATEGORY",
				from: "categories.servicesCategory",
				placeholder: "Services",
			},

			{
				title: "Industry Category",
				type: "MultiCheckSelect",
				key: "industryCategory",
				source: "INDUSTRY_CATEGORY",
				from: "categories.industryCategory",
				placeholder: "Industry",
			},

			{
				title: "Skills Category",
				type: "MultiCheckSelect",
				key: "skillsCategory",
				source: "SKILLS_CATEGORY",
				from: "categories.skillsCategory",
				placeholder: "Skills",
			},
		],
	},
};
