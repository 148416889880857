import { UIFields } from "@griddo/core";

export const themeSelector: UIFields.VisualUniqueSelection = {
	title: "Subtheme",
	key: "subtheme",
	type: "VisualUniqueSelection",
	columns: 8,
	options: [
		{ value: "default", img: "/thumbnails/subthemes/default@2x.png" },
		{ value: "inverse", img: "/thumbnails/subthemes/inverse@2x.png" },
		{
			value: "cultureDefault",
			img: "/thumbnails/subthemes/culture-default@2x.png",
		},
		{
			value: "cultureInverse",
			img: "/thumbnails/subthemes/culture-inverse@2x.png",
		},
	],
	mandatory: true,
};
