import { Schema } from "@griddo/core";

const schema: Schema.ModuleCategories = [
	{ label: "Basic", value: "basic" },
	{ label: "Collection", value: "collection" },
	{ label: "Content", value: "content" },
	{ label: "Distributors", value: "distributors", featured: true },
	{ label: "Featured", value: "featured" },
	{ label: "Heros", value: "heros" },
	{ label: "Masonry", value: "masonry" },
	{ label: "Sliders", value: "sliders" },
	{ label: "Spacers", value: "spacers", featured: true },
];

export default schema;
