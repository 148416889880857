import { HeroVideoProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchor, heading, themeSelector } from "@schemas/presets";

const schema: Schema.Module<HeroVideoProps> = {
	schemaType: "module",
	component: "HeroVideo",
	category: "heros",
	displayName: "HeroVideo",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading },

				{
					type: "RichText",
					title: "Content",
					key: "content",
					placeholder: "Type a content",
					hideable: true,
					isMockup: true,
					humanReadable: true,
					// h3
				},

				{
					title: "Link",
					type: "ComponentContainer",
					whiteList: ["Button"],
					key: "link",
					hideable: true,
					mandatory: true,
				},

				{
					title: "Video",
					type: "ComponentContainer",
					mandatory: true,
					key: "video",
					whiteList: ["Video"],
				},
			],
		},
		{
			title: "config",
			fields: [
				{ ...anchor },
				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/HeroVideo/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/HeroVideo/layout2.png",
						},
					],
				},
				{ ...themeSelector },
			],
		},
	],

	default: {
		component: "HeroVideo",
		title: {
			content: "Lorem Ipsum",
			tag: "h2",
		},
		content: "Lorem ipsum paragraph",
		layout: "layout1",
		subtheme: "inverse",
		link: {
			component: "Button",
		},
		video: {
			component: "Video",
		},
	},

	thumbnails: {
		"1x": "/thumbnails/modules/HeroVideo/thumbnail@1x.png",
		"2x": "/thumbnails/modules/HeroVideo/thumbnail@2x.png",
	},
};

export default schema;
