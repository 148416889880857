import { PageDistributorProps } from "@autoTypes";
import { Schema } from "@griddo/core";
import { anchor, heading, themeSelector } from "@schemas/presets";

const schema: Schema.Module<PageDistributorProps> = {
	schemaType: "module",
	component: "PageDistributor",
	category: "distributors",
	displayName: "PageDistributor",

	configTabs: [
		{
			title: "content",
			fields: [
				{ ...heading, title: "Title 1", key: "title1", mandatory: true },

				{
					type: "ComponentContainer",
					title: "Link 1",
					key: "link1",
					whiteList: ["Button"],
					hideable: true,
				},

				{
					type: "ComponentContainer",
					title: "Visual content 1",
					whiteList: ["FullImage", "Video"],
					key: "visualContent1",
					mandatory: true,
					helptext: "Recommended minimum image size: 792x514",
				},

				{ ...heading, title: "Title 2", key: "title2" },

				{
					type: "ComponentContainer",
					title: "Link 2",
					key: "link2",
					whiteList: ["Button"],
					hideable: true,
				},

				{
					type: "ComponentContainer",
					title: "Visual content 2",
					whiteList: ["FullImage", "Video"],
					key: "visualContent2",
					helptext: "Recommended minimum image size: 792x514",
				},
			],
		},

		{
			title: "config",
			fields: [
				{ ...anchor },

				{
					type: "VisualUniqueSelection",
					title: "Layout",
					key: "layout",
					columns: 2,
					mandatory: true,
					options: [
						{
							value: "layout1",
							img: "/thumbnails/modules/PageDistributor/layouts/layout1.png",
						},
						{
							value: "layout2",
							img: "/thumbnails/modules/PageDistributor/layouts/layout2.png",
						},
					],
				},

				{ ...themeSelector },
			],
		},
	],

	default: {
		component: "PageDistributor",
		title1: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		link1: {
			component: "Button",
			link: { text: "Saber más", linkType: "url", url: {}, modal: {} },
		},
		visualContent1: {
			fullImage: {
				component: "FullImage",
			},
			video: {
				component: "Video",
			},
		},
		title2: {
			content: "Lorem ipsum",
			tag: "h2",
		},
		link2: {
			component: "Button",
			link: { text: "Saber más", linkType: "url", url: {}, modal: {} },
		},
		visualContent2: {
			fullImage: {
				component: "FullImage",
			},
			video: {
				component: "Video",
			},
		},
		subtheme: "default",
		layout: "layout1",
	},

	thumbnails: {
		"1x": "/thumbnails/modules/PageDistributor/thumbnail@1x.png",
		"2x": "/thumbnails/modules/PageDistributor/thumbnail@2x.png",
	},
};

export default schema;
