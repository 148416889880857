//
// Griddo Modules
//
// Every Module and their types must be imported and reexported.
// Yes, a barrel index :)

import { Component } from "@griddo/core";
import * as React from "react";

// Types
import { BasicContentProps } from "./BasicContent";
import { BasicTextProps } from "./BasicText";
import { CardCollectionProps } from "./CardCollection";
import { CardCollectionSliderProps } from "./CardCollectionSlider";
import { CaseDistributorProps } from "./CaseDistributor";
import { CasesProps } from "./Cases";
import { ContactProps } from "./Contact";
import { CustomImageProps } from "./CustomImage";
import { CustomVideoProps } from "./CustomVideo";
import { CypherCollectionProps } from "./CypherCollection";
import { DataCollectionProps } from "./DataCollection";
import { FeaturedAudiovisualProps } from "./FeaturedAudiovisual";
import { FooterProps } from "./Footer";
import { FullVisualProps } from "./FullVisual";
import { GraphicModulesProps } from "./GraphicModules";
import { HeaderProps } from "./Header";
import { HeroProjectProps } from "./HeroProject";
import { HeroVideoProps } from "./HeroVideo";
import { HorizontalScrollCardCollectionProps } from "./HorizontalScrollCardCollection";
import { InnerHeroProps } from "./InnerHero";
import { MasonryAudiovisualProps } from "./MasonryAudiovisual";
import { PageDistributorProps } from "./PageDistributor";
import { ProjectDistributorProps } from "./ProjectDistributor";
import { QuoteProps } from "./Quote";
import { ShowcaseProps } from "./Showcase";
import { SliderProps } from "./Slider";
import { SpacerProps } from "./Spacer";
import { TeamDistributorProps } from "./TeamDistributor";
import { TimelineProps } from "./Timeline";
import { WysiwygProps } from "./Wysiwyg";

// Modules
const BasicContent = React.lazy(() => import("./BasicContent"));
const BasicText = React.lazy(() => import("./BasicText"));
const CardCollection = React.lazy(() => import("./CardCollection"));
const CardCollectionSlider = React.lazy(() => import("./CardCollectionSlider"));
const CaseDistributor = React.lazy(() => import("./CaseDistributor"));
const Cases = React.lazy(() => import("./Cases"));
const Contact = React.lazy(() => import("./Contact"));
const CustomImage = React.lazy(() => import("./CustomImage"));
const CustomVideo = React.lazy(() => import("./CustomVideo"));
const CypherCollection = React.lazy(() => import("./CypherCollection"));
const DataCollection = React.lazy(() => import("./DataCollection"));
const FeaturedAudiovisual = React.lazy(() => import("./FeaturedAudiovisual"));
const Footer = React.lazy(() => import("./Footer"));
const FullVisual = React.lazy(() => import("./FullVisual"));
const GraphicModules = React.lazy(() => import("./GraphicModules"));
const Header = React.lazy(() => import("./Header"));
const HeroProject = React.lazy(() => import("./HeroProject"));
const HeroVideo = React.lazy(() => import("./HeroVideo"));
const HorizontalScrollCardCollection = React.lazy(
	() => import("./HorizontalScrollCardCollection")
);
const InnerHero = React.lazy(() => import("./InnerHero"));
const MasonryAudiovisual = React.lazy(() => import("./MasonryAudiovisual"));
const PageDistributor = React.lazy(() => import("./PageDistributor"));
const ProjectDistributor = React.lazy(() => import("./ProjectDistributor"));
const Quote = React.lazy(() => import("./Quote"));
const Showcase = React.lazy(() => import("./Showcase"));
const Slider = React.lazy(() => import("./Slider"));
const Spacer = React.lazy(() => import("./Spacer"));
const TeamDistributor = React.lazy(() => import("./TeamDistributor"));
const Timeline = React.lazy(() => import("./Timeline"));
const Wysiwyg = React.lazy(() => import("./Wysiwyg"));

// Modules for <GriddoModule>
const modules = {
	BasicContent,
	BasicText,
	CardCollection,
	CardCollectionSlider,
	CaseDistributor,
	Cases,
	Contact,
	CustomImage,
	CustomVideo,
	CypherCollection,
	DataCollection,
	FeaturedAudiovisual,
	Footer,
	FullVisual,
	GraphicModules,
	Header,
	HeroProject,
	HeroVideo,
	HorizontalScrollCardCollection,
	InnerHero,
	MasonryAudiovisual,
	PageDistributor,
	ProjectDistributor,
	Quote,
	Showcase,
	Slider,
	Spacer,
	TeamDistributor,
	Timeline,
	Wysiwyg,
};

// Types for <GriddoModule>
export type ModulesProps =
	| BasicContentProps
	| BasicTextProps
	| CardCollectionProps
	| CardCollectionSliderProps
	| CaseDistributorProps
	| CasesProps
	| ContactProps
	| CustomImageProps
	| CustomVideoProps
	| CypherCollectionProps
	| DataCollectionProps
	| FeaturedAudiovisualProps
	| FooterProps
	| FullVisualProps
	| GraphicModulesProps
	| HeaderProps
	| HeroProjectProps
	| HeroVideoProps
	| HorizontalScrollCardCollectionProps
	| InnerHeroProps
	| MasonryAudiovisualProps
	| PageDistributorProps
	| ProjectDistributorProps
	| QuoteProps
	| ShowcaseProps
	| SliderProps
	| SpacerProps
	| TeamDistributorProps
	| TimelineProps
	| WysiwygProps;

// Module named export
export {
	BasicContent,
	BasicText,
	CardCollection,
	CardCollectionSlider,
	CaseDistributor,
	Cases,
	Contact,
	CustomImage,
	CustomVideo,
	CypherCollection,
	DataCollection,
	FeaturedAudiovisual,
	Footer,
	FullVisual,
	GraphicModules,
	Header,
	HeroProject,
	HeroVideo,
	HorizontalScrollCardCollection,
	InnerHero,
	MasonryAudiovisual,
	PageDistributor,
	ProjectDistributor,
	Quote,
	Showcase,
	Slider,
	Spacer,
	Timeline,
	Wysiwyg,
};

/*
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱                       ╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱  You shall not pass!  ╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱                       ╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱╱
*/

// Griddo bundle export
export default modules;

/**
 * `<GriddoModule>`
 *
 * Render modules with their Types dinamically and wrapped them with Griddo
 * functionalities.
 *
 * @example
 * <GriddoModule component="BasicModule" {...basicModuleProps} />
 *
 * Check out the [documentation](https://griddoio.notion.site/GriddoComponent-y-GriddoModule-en-la-instancia-bad16303bb4b49e0b696b62d1f6c40e9)
 */
export const GriddoModule = (props: ModulesProps) => (
	<Component libComponents={modules} {...props} />
);
