import { Schema } from "@griddo/core";
import { defaultLink } from "@schemas/presets";

const schema: Schema.Component = {
	schemaType: "component",
	displayName: "Button",
	component: "Button",

	configTabs: [
		{
			title: "content",
			fields: [
				{
					_autoType: {
						doc: "/** Link field type */",
					},
					type: "LinkField",
					title: "Link",
					key: "link",
					whiteList: [],
					mandatory: true,
				},

				{
					_autoType: {
						doc: "/** Conditional field type */",
					},
					title: "Show left icon",
					type: "ConditionalField",
					key: "showLeftIcon",
					options: [
						{
							name: "yes",
							value: true,
							title: "Yes",
						},

						{
							name: "no",
							value: false,
							title: "No",
						},
					],

					fields: [
						{
							condition: true,
							title: "Left icon",
							key: "leftIcon",
							type: "VisualUniqueSelection",
							columns: 8,
							options: [
								{
									value: "arrowLeft",
									img: "/thumbnails/components/Button/icons/arrow-left@2x.png",
								},
								{
									value: "calendar",
									img: "/thumbnails/components/Button/icons/calendar@2x.png",
								},
							],
						},
					],
				},

				{
					_autoType: {
						doc: "/** Conditional field type */",
					},
					title: "Show right icon",
					type: "ConditionalField",
					key: "showRightIcon",
					options: [
						{
							name: "yes",
							value: true,
							title: "Yes",
						},

						{
							name: "no",
							value: false,
							title: "No",
						},
					],

					fields: [
						{
							condition: true,
							title: "Right icon",
							key: "rightIcon",
							type: "VisualUniqueSelection",
							columns: 8,
							options: [
								{
									value: "arrowRight",
									img: "/thumbnails/components/Button/icons/arrow-right@2x.png",
								},
								{
									value: "calendar",
									img: "/thumbnails/components/Button/icons/calendar@2x.png",
								},
							],
						},
					],
				},
			],
		},

		{
			title: "config",
			fields: [
				{
					_autoType: {
						doc: "/** Button variant style */",
					},
					title: "Variant",
					key: "variant",
					type: "VisualUniqueSelection",
					columns: 4,
					options: [
						{
							value: "primary",
							img: "/thumbnails/components/Button/styles/primary@1x.png",
						},
						{
							value: "secondary",
							img: "/thumbnails/components/Button/styles/secondary@1x.png",
						},
						{
							value: "text",
							img: "/thumbnails/components/Button/styles/text-button@1x.png",
						},
						{
							value: "cta",
							img: "/thumbnails/components/Button/styles/cta@2x.png",
						},
					],
				},
			],
		},
	],

	default: {
		component: "Button",
		variant: "primary",
		link: {
			...defaultLink,
			text: "Link button",
			linkType: "url",
			modal: {},
		},
		showLeftIcon: false,
		showRightIcon: false,
		leftIcon: "arrowLeft",
		rightIcon: "arrowRight",
	},
};

export default schema;
