import { Schema } from "@griddo/core";

const schema: Schema.MultiPageComponent = {
	schemaType: "component",
	component: "MultiPageComponent",
	displayName: "MultiPageComponent",

	configTabs: [
		{
			title: "Seo",
			fields: [
				{
					title: "Page title",
					key: "title",
					type: "TextField",
					humanReadable: true,
				},

				{
					title: "Section Slug",
					key: "sectionSlug",
					type: "TextField",
				},

				{
					title: "Meta title",
					key: "metaTitle",
					type: "TextField",
					humanReadable: true,
				},

				{
					title: "Meta description",
					key: "metaDescription",
					type: "TextField",
					humanReadable: true,
				},
			],
		},
	],

	default: {
		component: "MultiPageComponent",
		title: "Tab title",
		sectionSlug: "tab-01",
		metaTitle: "The meta title",
		metaDescription: "The meta description",
	},

	thumbnails: {
		"1x": "/thumbnails/templates/MultiPageComponent/thumbnail@1x.png",
		"2x": "/thumbnails/templates/MultiPageComponent/thumbnail@2x.png",
	},
};

export default schema;
