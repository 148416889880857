import Button from "./Button";
import AccordionCard from "./Cards/AccordionCard";
import BasicCard from "./Cards/BasicCard";
import CypherCard from "./Cards/CypherCard";
import DataCard from "./Cards/DataCard";
import ImageCard from "./Cards/ImageCard";
import SlideCard from "./Cards/SlideCard";
import TextCard from "./Cards/TextCard";
import DataGraphic from "./Graphics/DataGraphic";
import DesignOpsGraphic from "./Graphics/DesignOpsGraphic";
import DevOpsGraphic from "./Graphics/DevOpsGraphic";
import DigitalProductGraphic from "./Graphics/DigitalProductGraphic";
import GoldenCircleGraphic from "./Graphics/GoldenCircleGraphic";
import SquiggleGraphic from "./Graphics/SquiggleGraphic";
import StrategyGraphic from "./Graphics/StrategyGraphic";
import IconButton from "./IconButton";
import LinkableImage from "./LinkableImage";
import MultiPageComponent from "./MultiPageComponent";
import Slide from "./Slide";
import Video from "./Video";

export default {
	AccordionCard,
	BasicCard,
	Button,
	CypherCard,
	DataCard,
	DataGraphic,
	DesignOpsGraphic,
	DevOpsGraphic,
	DigitalProductGraphic,
	GoldenCircleGraphic,
	IconButton,
	ImageCard,
	LinkableImage,
	MultiPageComponent,
	Slide,
	SlideCard,
	StrategyGraphic,
	SquiggleGraphic,
	TextCard,
	Video,
};
