import Case from "./Case";
import FullImage from "./FullImage";
import Image from "./Image";
import Milestone from "./Milestone";

export default {
	Case,
	FullImage,
	Image,
	Milestone,
};
